<template>
  <v-app>
    <v-main>
      <nav>
        <router-link to="/"></router-link>
      </nav>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
nav {
  padding: 10px;
}

nav a {
  margin: 0 10px;
  text-decoration: none;
}

nav a.router-link-exact-active {
  font-weight: bold;
}
</style>
