// /src/router/index.js
import UnderConstruction from '@/components/UnderConstruction.vue';
import Index from '@/components/landing-page/Index.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'UnderConstruction',
    component: UnderConstruction
  },
  {
    path: '/testing',
    name: 'LandingPage',
    component: Index
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
