<template>
    <div class="under-construction">
        <img src="https://static.vecteezy.com/system/resources/previews/001/970/338/non_2x/building-under-construction-site-free-vector.jpg" alt="Sitio en Construcción" class="construction-image">
        <h1>Site Under Construction</h1>
    </div>
</template>

<script>
import axios from '../axios';

export default {
    name: 'UnderConstruction',
    data: () => ({
        message: '',
    }),

    mounted() {
        axios.get('/example')
        .then(response => {
            this.message = response.data.message;
            console.log(this.message)
        })
        .catch(error => {
            console.error(error);
        });
    },
}
</script>

<style scoped>
    .under-construction {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        /* Ocupa toda la altura de la pantalla */
        background-color: #f0f0f0;
        /* Color de fondo opcional */
    }

    .construction-image {
        max-width: 100%;
        /* Ajustar la imagen al tamaño del contenedor */
        max-height: 80vh;
        /* Limitar la altura máxima de la imagen */
    }

    h1 {
        font-size: 2rem;
        margin-top: 20px;
        color: #333;
    }
</style>